<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="form.tab === 'article'">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <template v-if="form.tab === 'article'">
        <div class="filter-item">
          <el-form-item label="文章标题：" prop="article_keyword">
            <el-input v-model="form.article_keyword" placeholder="请输入文章标题"></el-input>
          </el-form-item>
          <el-form-item label="作者：" prop="article_author">
            <el-input v-model="form.article_author" placeholder="请输入作者名称"></el-input>
          </el-form-item>
          <el-form-item label="来源：" prop="source_name">
            <el-input v-model="form.source_name" placeholder="请输入文章来源"></el-input>
          </el-form-item>
        </div>
        <div class="filter-item">
          <el-form-item label="分类：" prop="category_id">
            <el-select v-model="form.category_id" placeholder="请选择文章分类">
              <el-option v-for="(item, index) in ArticleCategoryArr" :key="index" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型：" prop="type">
            <el-select v-model="form.type" placeholder="请选择文章类型">
              <el-option v-for="(item, index) in typeArr" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="置顶状态：" prop="is_top">
            <el-select v-model="form.is_top" placeholder="请选择置顶状态">
              <el-option v-for="(item, index) in topStatusArr" :key="index" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </template>

      <div class="filter-item" v-show="form.tab === 'activity'">
        <el-form-item label="活动名称：" prop="activity_keyword">
          <el-input v-model="form.activity_keyword" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="活动状态：" prop="activity_status">
          <el-select v-model="form.activity_status" placeholder="请选择活动状态">
            <el-option v-for="(item, index) in StatusArr" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item" v-show="form.tab === 'live'">
        <el-form-item label="直播名称：" prop="live_keyword">
          <el-input v-model="form.live_keyword" placeholder="请输入直播名称"></el-input>
        </el-form-item>
        <el-form-item label="直播状态：" prop="live_status">
          <el-select v-model="form.live_status" placeholder="请选择直播状态">
            <el-option v-for="(item, index) in StatusArr" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item" v-show="form.tab === 'collect'">
        <el-form-item label="用户名称：" prop="user_keyword">
          <el-input v-model="form.user_keyword" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker :label="timeTitle[form.tab]" start-prop="start_time"
          :start-time.sync="form.start_time" end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { articleCategorys } from "../api/check-brand";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      timeTitle: {
        article: "发布时间：",
        activity: "活动时间：",
        collect: "收藏时间：",
      },
      StatusArr: [
        { id: -1, name: "全部" },
        { id: 0, name: "未开始" },
        { id: 1, name: "进行中" },
        { id: 2, name: "已结束" },
        { id: 3, name: "已取消" },
      ],
      topStatusArr: [
        { id: -1, name: "全部" },
        { id: 0, name: "未置顶" },
        { id: 1, name: "已置顶" },
      ],
      // 文章类型选项
      typeArr: [
        { id: -1, name: "全部" },
        { id: 0, name: "纯文本" },
        { id: 1, name: "图文" },
        { id: 2, name: "大图" },
        { id: 3, name: "视频" },
      ],
      // 文章分类选项
      ArticleCategoryArr: [],
    };
  },
  methods: {
    // 获取文章分类选项数据
    getArticleCategorys() {
      articleCategorys()
        .then((res) => {
          this.ArticleCategoryArr = res.data;
        })
        .catch((err) => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    this.getArticleCategorys();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>