import api from "@/base/utils/request";

// 获取查看列表
export const CheckList = data => {
  return api({
    url: "/admin/cyc/brand/relations",
    method: "post",
    data
  });
};

// 移除文章/活动
export const DeleteList = data => {
  return api({
    url: "/admin/cyc/brand/deleteBrand",
    method: "post",
    data
  });
};

// 置顶文章
export const setTop = data => {
  return api({
    url: "/admin/cyc/brand/top",
    method: "post",
    data
  });
};

// 添加收藏会员
export const collectUser = data => {
  return api({
    url: "/admin/cyc/brand/userCollect",
    method: "post",
    data
  });
};

// 文章来源选项数据
export const sourceOptions = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data
  });
};

// 文章分类选项数据
export const articleCategorys = data => {
  return api({
    url: "/admin/portal/category/allShow",
    method: "post",
    data
  });
};
